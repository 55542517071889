@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

.TextAndImageInTwoColumns {
    //margin-left: 0px;
    //margin-right: 0px;
    .row {
        //align-items: center;
        //margin-left: 0px;
        //margin-right: 0px;
    }

    &-text {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 2rem !important;
        font-size: 1.3rem;
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            padding: 4rem !important;
        }

        // CSS Bubbles
        //&::before {
        //    content: "";
        //    position: absolute;
        //    top: -10rem;
        //    left: -10rem;
        //    width: 65%;
        //    height: auto;
        //    aspect-ratio: 1;
        //    border-radius: 50%;
        //    background: radial-gradient(circle at 0 0px, #fff, rgba(255,255,255,0) 40%);
        //    z-index: 1; //z-index
        //    opacity: .5;
        //    pointer-events: none;
        //    transform: rotate(170deg);
        //}
    }

    //.order-2 {
    //    .TextAndImageInTwoColumns-text {
    //        &::before {
    //            left: unset;
    //            right: -10rem;
    //            transform: rotate(270deg);
    //        }
    //    }
    //}

    .col-12:not(.TextAndImageInTwoColumns-image) {
        @include media-breakpoint-down(sm) {
            order: 2;
        }
    }

    &-image {
        position: relative;
        aspect-ratio: 1;

        @supports not (aspect-ratio: 1) {
            padding-bottom: 100%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
